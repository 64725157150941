/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* Start Modal*/
.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
  .close:hover,
  .close:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  top: 20%;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
}
.modal.fade.in {
  top: 10%;
}
.modal-header .close {
  margin-top: -2px;
}
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.clickable {
  cursor:pointer;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
.clearfix:before,
.clearfix:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}
.clearfix:after,
.modal-footer:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.affix {
  position: fixed;
}

.footer-btn {
    padding: 6px 36px;
    width: 91%;
    max-width: 24rem;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
}
.modal-footer-center{
    text-align: center;
}

.modal-x-sm{
    max-width: 284px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    transform: translate(-50%, -50%);
}
.modal-ok-btn
{
    font-size: 20px;
    background: white;
    color: #3880ff;
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
}
.modal-body-flow
{
    height: 60vh;
    overflow-y: auto;
}
.close-left{
    float: left;
}
/* End Modal*/

/* You can add global styles to this file, and also import other style files */
/* to be moved leter to ionic theme styles.scss*/
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

@media (min-width: 600px) {
    .modal-sm {
      max-width: 800px !important;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 700px;
      transform: translate(-50%, -50%);
    }
  }

@media (min-width: 900px) {
    .modal-lg {
      max-width: 1200px !important;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1000px;
      transform: translate(-50%, -50%);
    }
  }

.ds-ripple-container {
    height: 100vh;
    width: 100%;
    display: flex;
    background-color: #a4a4a4;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

ion-button {
    --border-width: 1px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.top-banner {
    font-size: small;
    max-width: 190px;
}

.header-toolbar {
    display: flex;
    align-items: center;
}

@media (max-width: 576px) {
    .container {
        padding-right: 0;
        padding-left: 0;
    }

    .top-banner {
        max-width: 290px;
    }

    .header-logo {
        max-height: 45px;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
        padding-right: 0;
        padding-left: 0;
    }

    .top-banner {
        max-width: 350px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }

    .top-banner {
        max-width: 400px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }

    .top-banner {
        max-width: 450px;
        font-size: 14px;
        font-weight: bold;
    }

    .header-toolbar {
        min-height: 60px;
        // margin-top: 6px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }

    .top-banner {
        max-width: 900px;
        font-size: 14px;
        font-weight: bold;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }

    .top-banner {
        max-width: 900px;
        font-size: 14px;
        font-weight: bold;
    }
}

.icon-badge {
    border-radius: 20px;
    position: absolute;
    left: 13px;
}

html[dir="rtl"] .icon-badge {
    left: unset;
    right: 9px;
}

.icon-badge.md {
    top: -10px;
}

.icon-badge.ios {
    top: 2px;
}

ion-skeleton-text {
    --background-rgb: 110, 110, 110 !important;
}

.header-logo {
    cursor: pointer;
    max-width: 130px;
    padding: 4px 1px 4px;
}

.default-header-logo {
    width: 70px;
}

@media (max-width: 576px) {
    .header-logo {
        max-width: 90px;
        padding: 1px;
    }
}

.box {
    border: 1px solid red;
}

.border-top {
    border-top: 0.5px solid;
}

/* Works on Firefox */
.thin-scroll {
    scrollbar-width: thin;
    scrollbar-color: #e6e6e6;
}

/* Works on Chrome, Edge, and Safari */
.thin-scroll::-webkit-scrollbar {
    max-width: 6px;
    max-height: 6px;
}

.thin-scroll::-webkit-scrollbar-track {
    background: #e6e6e6;
}

.thin-scroll::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 5px;
}

.card-border {
    border: 1px solid #e8e8e8;
}

.section-header ion-col:first-child div {
    padding-inline-start: 12px;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.flex-column {
    flex-direction: column;
}

.box-shadow {
    box-shadow: 0 18px 36px 0 rgb(213 215 225 / 49%);
    padding-inline-end: 5px;
    border-radius: 18px;
}

.h:hover {
    border-right: solid 4px;
}

.align-item-center {
    align-items: center;
}

.justify-content-around {
    justify-content: space-around;
}

.bold {
    font-weight: bold;
}

.no-line-segments {
    --indicator-color: transparent !important;
    --indicator-color-checked: transparent !important;
}

.justify-items-center {
    justify-items: center;
}

.pointer-cursor {
    cursor: pointer;
}

.quantity-symbol {
    display: inline-block;
    color: gray;
    white-space: nowrap;
    margin-inline-end: 4px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-24 {
    font-size: 24px;
}

.font-16 {
    font-size: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}

.text-break {
    margin: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    hyphens: auto;
}

.grid-tablet {
    @media only screen and (max-width: 768px) {
        display: grid;
    }
}

.round-5 {
    --border-radius: 5px;
}

.line- {
    width: 1px;
}

.auto-margin {
    margin: auto;
}

.margin-block-zero {
    margin-block: 0px;
}

.padding-block-zero {
    padding-block: 0px;
}

.rtl-phone {
    direction: ltr;
    text-align: right;
}

.basic-btn {
    width: 91%;
    max-width: 24rem;
}

.long-btn {
    height: 2.8rem;
}

.basic-input {
    background-color: #ffff;
    border: 0.5px solid #bbbb;
    border-radius: 8px;
    margin-block: 12px;
    width: 100%;
    max-width: 25rem;
    padding: 0 1rem !important;
}

.w-100 {
    width: 100%;
    max-width: 100%;
}

.basic-select {
    @extend .basic-input;
    height: 2.4rem;
    margin-top: 14px;
    padding-inline: 1px;
}

hr.solid {
    border-top: 0.5px solid rgb(0 0 0 / 12%);
}

.mt-0 {
    margin-top: 0px;
}

.light-gray {
    color: #adadad;
}

.gray {
    color: gray;
}

.max-width-500 {
    max-width: 500px;
}

.max-width-700 {
    max-width: 700px;
}

.max-width-900 {
    max-width: 900px;
}

.mb-2 {
    margin-bottom: 2rem;
}

.m-inline-1 {
    margin-inline: 1rem;
}

.m-inline-2 {
    margin-inline: 2rem;
}

.radius-20 {
    border-radius: 20px;
}

.h-100 {
    height: 100%;
}

//note
.note {
    background-color: #ffff;
    border: 0.5px solid #bbbb;
    border-radius: 8px;
    padding: 8px;
}

figure {
    margin: 8px;
}

.table td {
    border: 1px solid;
    padding: 4px;
}

// Progress bar
// CONFIG
$primary-color: #f4f5f8;
$active-color: #2dd36f;
$done-color: #b4b4b4;

.progress-bar {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}

.progress-step {
    text-align: center;
    position: relative;

    &:before,
    &:after {
        background-color: $primary-color;
        content: "";
        height: 2px;
        position: absolute;
        z-index: -1;
        top: 20px;
    }

    &:after {
        width: 100%;
    }

    &:last-of-type.is-active:after {
        background-color: $primary-color;
    }

    @media screen and (min-width: 640px) {
        &:first-of-type:before {
            right: 50%;
            width: 50%;
        }
    }

    @media screen and (max-width: 640px) {
        &:first-of-type:before,
        &:last-of-type:after {
            background-color: white !important;
        }
    }

    &:last-of-type:after {
        left: 50%;
        width: 50%;
    }

    .step-count {
        background-color: $primary-color;
        height: 50px;
        width: 50px;
        margin: 0 auto;
        border-radius: 50%;
        color: gray;
        line-height: 54px;
        z-index: 100;
        border: 2px solid #b4b4b4;
        font-size: 1.5rem;
        font-weight: 900;
    }

    .is-active {
        background-color: $active-color;
        color: white !important;
        border-color: $active-color;
    }

    .is-done {
        background: $done-color;
        color: white !important;
        border-color: $done-color;
    }
}

.step-description {
    font-size: 0.85rem;
}

.box-selected {
    --border-style: solid;
}

.not-found-icon-container {
    margin: auto;
    margin-top: 2rem;
}

.not-found-icon {
    zoom: 8;
}

.loading-icon {
    zoom: 3;
    margin: auto;
    margin-top: 5%;
}

.text-secound-footer {
    font-size: 16px;
    font-weight: 500;
    margin-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-40 {
    max-height: 40px;
}

.vl {
    border-left: 0.5px solid #dddd;
    height: 80px;
    padding: 10px;
}

.vl-R {
    border-right: 0.5px solid #dddd;
    height: 80px;
    padding: 10px;
}

.flex-item_50 {
    display: flex;
    flex-basis: 50%;
    max-width: 18rem;
}

.flex-item_20 {
    display: flex;
    flex-basis: 20%;
}

.margin-inline--16 {
    margin-inline-start: -16px;
}

.base-modal .modal-wrapper {
    --border-radius: 0px;
    --width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;

    @media (min-width: 770px) {
        --width: 36%;
        --border-radius: 20px;
    }
}

.red-star {
    color: red;
    font-size: large;
    line-height: 0px;
}

.favorite-btn {
    border: 1px solid;
    border-radius: 6px;
    margin-top: 0;
    --background: transparent;
    --box-shadow: none;
    margin-inline-start: 8px;
}

.coupon-input {
    border: 0.5px solid #bbbb;
    border-radius: 8px;
    --padding-start: 10px !important;
}

ion-icon {
    pointer-events: none;
}

.alert-card {
    margin: 2px;
    padding: 1.5rem;
    font-weight: bold;
    border-radius: 4px;
}

.phone-number .ion-intl-tel-input-number {
    padding-bottom: 3.4px;
}

.phone-number-login .ion-intl-tel-input-number {
    padding-bottom: 1.6px;
}

.single-country-phone-number .ionic-selectable-icon {
    width: 2px !important;
    opacity: 0;
}

.text-decoration-none {
    text-decoration: none;
}

.custom-alert {
    .alert-wrapper {
        min-width: 300px;
        padding: 10px;
    }

    .alert-message {
        text-align: justify;
    }

    .alert-button {
        background: rgba(61, 194, 255, 0.12);
        border-radius: 16px;
    }
}

.guest-alert {
    .alert-wrapper {
        min-width: 300px;
        padding: 10px;
    }

    .alert-message {
        text-align: justify;
    }
}

.justify-content-center {
    justify-content: center;
}

.feature-img {
    width: 40px;
    height: 40px;
}

.btn-view-cart {
    position: -webkit-sticky;
    position: sticky;
    bottom: 10px;
    z-index: 10;
    top: 0;
    display: table;
    text-align: center;
    margin: auto;
    width: 98%;

    .view-cart {
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
    }

    .icon-badge.md {
        top: 0;
        right: 7px;
    }
}
//  loyalty program in cart
.get-rewards-content {
    padding: 0.5rem;
    margin: 0.7rem 0 0;
    background-color: #f4f6f9;
    border-radius: 0.4375rem 0.4375rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.get-rewards-icon {
    background-color: #fff;
    min-width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-end: 0.875rem;
}
.get-rewards-text {
    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    span {
        color: #838da6;
    }
}
.language-alert {
    .alert-wrapper {
        width: 300px;
    }
    .alert-button-inner {
        justify-content: center;
    }
    .alert-button {
        border: 1px solid;
        margin: 0;
        margin-bottom: 9px;
        border-color: var(--store-color);
        color: var(--store-color);
    }
    .alert-button-role-cancel {
        color: red;
        border: none;
        width: 40%;
        margin: 0 auto;
    }
}
.white-background {
    background-color: #fff !important;
}
.text-align-center {
    text-align: center;
}
.brand-img {
    width: 50px;
    height: 30px;
    margin-inline-end: 5px;
}
ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}
.inherit-cursor {
    cursor: inherit;
}

.text-trnsform-none {
    text-transform: none;
}

.margin-end-8 {
    margin-inline-end: 8px;
}
.padding-end-8 {
    padding-inline-end: 8px;
}

ion-modal.wide-modal {
    --width: 80%;
    --height: 80%;
    --border-radius: 0;
    @media (max-width: 992px) {
        --width: 100%;
        --height: 100%;
    }
}
.mb-0 {
    margin-bottom: 0px;
}
.date-modal {
    --height: 80%;
    --border-radius: 10px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.error-color {
    color: red;
}
.confirm-alert {
    .alert-title {
        text-align: center;
        font-weight: bold;
    }
    .alert-message {
        text-align: center;
    }
    .alert-button {
        color: red;
    }
    .alert-button-role-cancel {
        color: #3171e0;
    }
}
ion-loading.loading-alert {
    --background: #e3edff;
    --spinner-color: var(--store-color);
    color: var(--store-color);
}
.date-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}
.font-12 {
    font-size: 12px;
}
.line-height-inherit {
    line-height: inherit;
}
.text-decoration-underline {
    text-decoration: underline;
}
// ion-toolbar.ios {
//     --background: white !important;
// }
.display-block {
    display: block;
}
.font-normal {
    font-weight: normal;
}
.zoom-container {
    position: relative;
    display: flex;
}


.img-zoom-container {
    position: relative;
}

.img-zoom-lens {
    position: absolute;
    border: 1px solid #fff;
    /*set the size of the lens:*/
    width: 100px;
    height: 100px;
}

.img-zoom-result-left {
    border: 1px solid #d4d4d4;
    /*set the size of the result div:*/
    width: 400px;
    height: 400px;
    position: absolute;
    top: 0;
    z-index: 5000;
    left: 50%;
}

.img-zoom-result-right {
    border: 1px solid #d4d4d4;
    /*set the size of the result div:*/
    width: 400px;
    height: 400px;
    position: absolute;
    top: 0;
    z-index: 5000;
    right: 50%;
}

.img-zoom-result-hide {
    visibility: hidden;
}

.swiper-slide img {
    width: 100%;
}